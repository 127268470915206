<template>
  <!-- 자동완성 -->
  <ApprovalAutocomplete
    v-if="editable"
    v-model="selected"
    :search-types="['MEMBER']"
    :error-message="userError"
    placeholder="사용자 검색"
    class="cr-autocomplete"
    @input="v$.user.$touch()"
    @blur="v$.user.$touch()"
  />
  <!-- 사용자 정보 -->
  <v-tooltip v-else bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-chip v-on="on" v-bind="attrs" small>
        {{ user.name }}
      </v-chip>
    </template>
    <span>{{ user.email }}</span>
  </v-tooltip>
</template>

<script>
import ApprovalAutocomplete from "@/approval/views/components/common/ApprovalAutocomplete";
import useVuelidate from "@vuelidate/core";
export default {
  name: "UserSelectFieldItem",
  components: { ApprovalAutocomplete },
  setup: () => ({ v$: useVuelidate() }),
  props: {
    value: {
      type: Object,
      description: "필드 데이타 (v-model)"
    },
    templateFrame: {
      type: Object,
      required: true,
      description: "필드 속성"
    },
    editable: {
      type: Boolean,
      default: false,
      description: "편집 모드"
    }
  },
  computed: {
    user: {
      get() {
        return {
          id: this.value?.id ?? "",
          email: this.value?.email ?? "",
          name: this.value?.name ?? ""
        };
      },
      set(val) {
        this.$emit("input", { ...val });
      }
    },
    selected: {
      get() {
        const selected = {
          type: "MEMBER",
          name: this.user.name,
          email: this.user.email,
          userId: this.user.id,
          valid: true
        };
        return this.user?.id > 0 ? selected : null;
      },
      set(val) {
        // 선택된 사용자가 없을 경우
        if (!(val?.userId > 0)) {
          this.user = { id: "", email: "", name: "" };
          return;
        }
        // 사용자 선택되었을 경우
        const { userId: id, email, name } = val;
        this.user = { id, email, name };
      }
    },
    userError() {
      const { $dirty, required } = this.v$.user;
      const errors = [];
      if (!$dirty) return errors;
      if (required.$invalid) errors.push("필수 입력사항 입니다.");
      return errors;
    }
  },
  methods: {
    async validate() {
      return await this.v$.$validate();
    }
  },
  validations() {
    return {
      user: {
        required: val => {
          if (!this?.templateFrame?.required) return true;
          return val?.id || false;
        }
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      // 기본값 초기화
      this.user = {
        id: this.value?.id ?? "",
        email: this.value?.email ?? "",
        name: this.value?.name ?? ""
      };
    });
  }
};
</script>
